import {
  Button as AuroraButton,
  Heading,
} from '@hurtigruten/design-system-components';
import Link from 'next/link';

import { useLocale, useTranslate } from '@hooks';
import { landing } from '@microcopies';
import { ContentfulImage } from '@atoms';
import { Fluid } from '@components';
import {
  mapLocaleToCruisesUri,
  mapLocaleToLoyaltyUri
} from '@src/utils/mappers/uriMappers';
import { SharedParagraph } from '@components/shared/paragraph';

const ReadyToExplore = ({ bannerImageUrl }: { bannerImageUrl?: string }) => {
  const locale = useLocale();
  const translateLanding = useTranslate(landing, (x) => x.landing);

  return (
    <section className="bg-warm-gray-2">
      <div className="flex flex-col w-full bg-white tablet:flex-row">
        <div className="relative w-full h-[192px] tablet:flex-1 tablet:h-auto">
          <ContentfulImage
            objectFit="cover"
            layout="fill"
            className="brightness-50"
            src={bannerImageUrl}
            alt={translateLanding((x) => x.findYourAdventureNow)}
          />
          <div className="absolute w-full h-20 bottom-[36%] bg-gradient-to-b from-transparent to-black-transparent-25" />
          <div className="absolute bottom-0 w-full h-[36%] bg-black-transparent-25" />
          <div
            className="absolute flex flex-col w-full gap-6 left-1/2 top-1/2 max-w-[230px] text-center items-center"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Heading
              level="2"
              appearance="headline-3"
              color="light"
              text={translateLanding((x) => x.areYouReadyToExplore)}
            />
            <Link href={`/${mapLocaleToCruisesUri(locale)}`} passHref legacyBehavior>
              <AuroraButton
                appearance="secondary"
                text={translateLanding((x) => x.findYourAdventureNow)}
              />
            </Link>
          </div>
        </div>
        <div className="tablet:flex-1">
          <Fluid className="border-b border-warm-gray-3">
            <div className="flex flex-col gap-6 my-8 max-w-[350px] mx-auto text-center items-center">
              <SharedParagraph noMargin textStyle="uppercase">
                {translateLanding((x) => x.ambassadorProgrammeTitle)}
              </SharedParagraph>
              <Heading
                level="2"
                appearance="headline-3"
                text={translateLanding((x) => x.joinOurWorldAndGetRewarded)}
              />
              <SharedParagraph textSize="large" noMargin>
                {translateLanding((x) => x.signUpToLoyaltyAndMore)}
              </SharedParagraph>
              <Link
                href={`/${locale}/${mapLocaleToLoyaltyUri(locale)}/`}
                passHref
                legacyBehavior>
                <AuroraButton
                  appearance="primary"
                  text={translateLanding((x) => x.joinTheProgramme)}
                />
              </Link>
            </div>
          </Fluid>
        </div>
      </div>
    </section>
  );
};

export default ReadyToExplore;
