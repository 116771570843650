import getConfig from 'next/config';

export const windowKey = '__featureFlags';
export const windowTimestampKey = '__featureFlagsTimestamp';

export interface IFeatureFlags {
  brightPatternChat?: boolean;
  countrySelection?: boolean;
  isCrimtanEnabled?: boolean;
  isHotjarEnabled?: boolean;
  isLoyaltyActive?: boolean;
  logFeatureFlagTimestamp?: boolean;
  shipInteractiveMap?: boolean;
  showContinueBookingButton?: boolean;
  showContinueBookingPreview?: boolean;
  showProfileExpeditions?: boolean;
  storiesFeatureToggle?: boolean;
  topicsFeatureToggle?: boolean;
  useContentfulAvailability?: boolean;
  isOptimizeEnabled?: boolean;
  nellieIDAPIHeader?: boolean;
  showInfoBanner?: boolean;
  showNewWhatsIncluded?: boolean;
  showDestinationFaq?: boolean;
  showAvailabilityPromotions?: boolean;
  isFAQSearchEnabled?: boolean;
  showOrderBrochures?: boolean;
  contentSecurityPolicy?: boolean;
  showLoyaltyDiscountField?: boolean;
  splitDiscountInWidget?: boolean;
  showDeeplink?: boolean;
  childInfoLink?: boolean;
  discountDisplay?: boolean;
  showArrivalDeparturePackages?: boolean;
  showDeeplinkDeparturesInVoyageHeader?: boolean;
  showExcursionsInBookingFlow?: boolean;
  knownPgIssues?: boolean;
  showUpdatedLoyaltyPage?: boolean;
  isAdyenEnabled?: boolean;
  expeditionPlanner?: boolean;
  contentfulXRay?: boolean;
  myaccount?: boolean;
  showDepartureDates?: boolean;
  showHrgGroupBanner?: boolean;
  enableAgentforceChatbot?: boolean;
}
declare global {
  interface Window {
    [windowKey]: IFeatureFlags;
    [windowTimestampKey]: number;
  }
}

type TGetConfigReturnValue = {
  serverRuntimeConfig?: {
    featureFlags?: {
      timestamp?: number;
      flags?: IFeatureFlags;
    };
  };
};

export const getFlags = (): IFeatureFlags => {
  // Browser

  if (typeof window !== 'undefined') {
    return window[windowKey] || {};
  }

  // Server
  const config = getConfig() as unknown as TGetConfigReturnValue;

  return config?.serverRuntimeConfig?.featureFlags?.flags ?? {};
};

export const getFeatureFlagTimestamp = (): number => {
  // Browser
  if (typeof window !== 'undefined') {
    return window[windowTimestampKey] || 0;
  }

  // Server
  const config = getConfig() as unknown as TGetConfigReturnValue;
  return config?.serverRuntimeConfig?.featureFlags?.timestamp ?? 0;
};

export const isFeatureEnabled = (flag: keyof IFeatureFlags): boolean =>
  getFlags()[flag] ?? false;

// Logging to help debug feature flags.
// Only happens in browser and when logFeatureFlagTimestamp is enabled
if (
  typeof window !== 'undefined' &&
  isFeatureEnabled('logFeatureFlagTimestamp')
) {
  const age = Math.floor(
    (new Date().getTime() - getFeatureFlagTimestamp()) / 1000
  );
  // eslint-disable-next-line no-console
  console.debug(`[Feature flags] Age = ${age}s`, getFlags());
}
