import React, { useState, useContext } from 'react';
import {
  Heading,
  Toggle,
  ButtonGroup,
  Button
} from '@hurtigruten/design-system-components';
import clsx from 'clsx';

import { XrayContext } from '@context/XrayProvider';
import { useMediaQuery } from '@hooks';
import { breakpoints } from '@src/utils';
import { SharedParagraph } from '@components/shared/paragraph';

import ContentfulLogoSVG from './ContentfulLogoSVG';

const ContentfulXrayControls = () => {
  const { xRayState } = useContext(XrayContext);
  const [showPopUp, setShowPopUp] = useState(false);
  const [xrayOn, setXrayOn] = useState(xRayState);
  const isMobile = useMediaQuery(breakpoints.tablet, true);

  return (
    <XrayContext.Consumer>
      {(context) => (
        <>
          <section
            className={clsx(
              'w-[370px] max-h-[500px] overflow-hidden fixed bottom-[100px] right-10 ',
              {
                'translate-y-[110%] translate-x-[90%] scale-0  delay-200 ':
                  !showPopUp
              }
            )}
          >
            <div
              className={clsx(
                'transition-all ease-out duration-200  relative bg-white drop-shadow-xl my-8 mx-5',
                {
                  'translate-y-0 translate-x-0 opacity-1 scale-100 ': showPopUp,
                  'translate-y-[110%] translate-x-[90%] opacity-0 scale-0 ':
                    !showPopUp
                }
              )}
            >
              <aside>
                <div className="flex items-center px-6 py-4 text-white bg-blue-900 child:pb-0">
                  <ContentfulLogoSVG width="30px" className="mr-4" />
                  <Heading
                    level="2"
                    appearance="headline-3"
                    text="Editorial Toolbox"
                  />
                </div>
                <div className="p-6">
                  <Heading
                    level="3"
                    appearance="headline-4"
                    text="General settings"
                  />
                  <hr />
                  <div className="mt-6 child:text-base child:font-medium">
                    <Toggle
                      label="X-ray mode"
                      value="xray"
                      checked={xrayOn}
                      id="xray"
                      onChange={() => setXrayOn(!xrayOn)}
                    />

                    <div className="py-2 pl-2 pr-6">
                      <SharedParagraph className="text-3sx">
                        Highlight components making up a page and provide a deep
                        link to the entry editor.
                      </SharedParagraph>
                    </div>
                  </div>
                  <div className="mt-10 ">
                    <ButtonGroup alignment="center">
                      <Button
                        appearance="secondary"
                        size="small"
                        text="Close"
                        onClick={() => setShowPopUp(false)}
                      />
                      <Button
                        appearance="primary"
                        size="small"
                        text="Apply changes"
                        onClick={() => {
                          context.setXray(xrayOn);
                          setShowPopUp(false);
                        }}
                      />
                    </ButtonGroup>
                  </div>
                </div>
              </aside>
            </div>
          </section>
          <div
            className={clsx('fixed bottom-10 right-10 z-100', {
              hidden: isMobile
            })}
          >
            <button
              aria-label="CF Xray"
              onClick={() => setShowPopUp(!showPopUp)}
              className="flex items-center justify-center bg-blue-900 rounded-full w-14 h-14"
            >
              <ContentfulLogoSVG width="60%" className="-ml-1" />
            </button>
          </div>
        </>
      )}
    </XrayContext.Consumer>
  );
};

export default ContentfulXrayControls;
