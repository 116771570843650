/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const talkToUs: Record<string, TTalkToUs> = {
  'en-AU': {
    talkToUs: {
      howCouldWeBestSupport: 'Please select how we could best support your exploration',
      joinTheLiveChat: 'Join the live chat',
      hiHowMayIHelpYou: 'Hi there! How may I help you?',
      justNow: 'Just now',
      scheduleAVideoCall: 'Schedule a video call with an Expedition Expert',
      tapIntoExpertKnowledge: 'Tap into the knowledge of our Expedition Experts for any questions you have on {destination}',
      talkWithAnExpert: 'Talk with an expert',
      call: 'Call',
      email: 'Email',
      subtitle: 'Have a question or booking enquiry? Get in touch with us today',
      reservationsHours: 'Reservations hours',
      scheduleExpertCall: 'Schedule an expert call',
      hideBanner: 'Hide banner',
      chat: 'Chat',
      openChat: 'Open a live chat with one of our customer service agents now and get help immediately.',
      tapIntoExpertKnowledgeGeneric: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination.',
      launchChatBot: 'Launch chat',
      helpWithBooking: 'More Information',
      helpWithBookingIntro: 'Some card providers may charge a foreign transaction fee for cards issued outside the EU, UK, and US. Please check with your bank regarding these fees. Hurtigruten is not responsible for this fee and does not receive anything from this.',
      bookingHelpTitle: 'We accept Visa, Mastercard, Maestro, American Express, UnionPay, and JCB. ',
      bookingHelpIntro: 'Can I set up a deposit? We can hold a booking for 7 days with an initial non-refundable 25% payment if booking more than 90 days before departure.',
      noThanks: 'No thanks',
      yesPlease: 'Yes please',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Deposit Information | Departures less than 90 days from booking, will require full payment. Any outstanding balance must be settled 90 days prior to departure.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Find out more',
      askQuestions: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination',
      talkToOurExperts: 'Schedule a video call with one of our Experts',
      scheduleCall: 'Schedule call'
    }
  },
  'en-GB': {
    talkToUs: {
      howCouldWeBestSupport: 'Please select how we could best support your exploration',
      joinTheLiveChat: 'Join the live chat',
      hiHowMayIHelpYou: 'Hi there! How may I help you?',
      justNow: 'Just now',
      scheduleAVideoCall: 'Got a question, or need help with a booking?',
      tapIntoExpertKnowledge: 'Our team of Expedition Experts are on hand to help answer any questions you have on {destination}. ',
      talkWithAnExpert: 'Talk with an expert',
      call: 'Call',
      email: 'Email',
      subtitle: 'Have a question or booking enquiry? Get in touch with us today',
      reservationsHours: 'Reservations hours',
      scheduleExpertCall: 'Schedule an expert call',
      hideBanner: 'Hide banner',
      chat: 'Chat',
      openChat: 'Open a live chat with one of our customer service agents now and get help immediately.',
      tapIntoExpertKnowledgeGeneric: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination.',
      launchChatBot: 'Launch chat',
      helpWithBooking: 'More Information',
      helpWithBookingIntro: 'Some card providers may charge a foreign transaction fee for cards issued outside the EU, UK, and US. Please check with your bank regarding these fees. Hurtigruten is not responsible for this fee and does not receive anything from this.',
      bookingHelpTitle: 'We accept Visa, Mastercard, Maestro, American Express, UnionPay, JCB and Trustly. ',
      bookingHelpIntro: 'Can I set up a deposit? We can hold a booking for 7 days with an initial non-refundable 25% payment if booking more than 90 days before departure.',
      noThanks: 'No thanks',
      yesPlease: 'Yes please',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Deposit Information | Departures less than 90 days from booking, will require full payment whilst any outstanding balance must be settled 90 days prior to departure. Additional payment questions can be found in the FAQ section.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Find out more',
      askQuestions: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination',
      talkToOurExperts: 'Got a question or need help with a booking?',
      scheduleCall: 'Schedule call'
    }
  },
  'en-US': {
    talkToUs: {
      howCouldWeBestSupport: 'Please select how we could best support your exploration',
      joinTheLiveChat: 'Join the live chat',
      hiHowMayIHelpYou: 'Hi there! How may I help you?',
      justNow: 'Just now',
      scheduleAVideoCall: 'Schedule a video call with an Expedition Expert',
      tapIntoExpertKnowledge: 'Tap into the knowledge of our Expedition Experts for any questions you have on {destination}',
      talkWithAnExpert: 'Talk with an expert',
      call: 'Call',
      email: 'Email',
      subtitle: 'Have a question or booking enquiry? Get in touch with us today',
      reservationsHours: 'Reservations hours',
      scheduleExpertCall: 'Schedule an expert call',
      hideBanner: 'Hide banner',
      chat: 'Chat',
      openChat: 'Open a live chat with one of our customer service agents now and get help immediately.',
      tapIntoExpertKnowledgeGeneric: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination.',
      launchChatBot: 'Launch chat',
      helpWithBooking: 'More Information',
      helpWithBookingIntro: 'Some card providers may charge a foreign transaction fee for cards issued outside the EU, UK, and US. Please check with your bank regarding these fees. Hurtigruten is not responsible for this fee and does not receive anything from this.',
      bookingHelpTitle: 'We accept Visa, Mastercard, Maestro, American Express, UnionPay, and JCB. ',
      bookingHelpIntro: 'Can I set up a deposit? We can hold a booking for 7 days with an initial non-refundable 25% payment if booking more than 90 days before departure.',
      noThanks: 'No thanks',
      yesPlease: 'Yes please',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Deposit Information | Departures less than 90 days from booking, will require full payment. Any outstanding balance must be settled 90 days prior to departure.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Find out more',
      askQuestions: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination',
      talkToOurExperts: 'Schedule a video call with one of our Experts',
      scheduleCall: 'Schedule call'
    }
  },
  'de-DE': {
    talkToUs: {
      howCouldWeBestSupport: 'Bitte wählen Sie aus, wie wir Sie auf Ihrer Expeditions-Seereise am besten unterstützen können',
      joinTheLiveChat: 'Nehmen Sie am Live-Chat teil',
      hiHowMayIHelpYou: 'Hallo! Wie kann ich Ihnen helfen?',
      justNow: 'Gleich jetzt',
      scheduleAVideoCall: 'Videoanruf mit einem Expeditionsexperten vereinbaren',
      tapIntoExpertKnowledge: ' Vereinbaren Sie unkompliziert einen Termin für eine Videoberatung. Nutzen Sie das Wissen unserer Expeditionsexperten für alle Fragen zu Ihrem Reiseziel.',
      talkWithAnExpert: 'Sprechen Sie mit einem Experten',
      call: 'Tel.',
      email: 'E-Mail-Adresse',
      subtitle: 'Haben Sie eine Frage oder eine Buchungsanfrage? Nehmen Sie noch heute Kontakt mit uns auf',
      reservationsHours: 'Reservierungszeiten',
      scheduleExpertCall: 'Dürfen wir Ihnen behilflich sein?',
      hideBanner: 'Banner ausblenden',
      chat: 'Chat',
      openChat: 'Öffnen Sie jetzt einen Live-Chat mit einem unserer Kundendienstmitarbeiter und erhalten Sie sofort Hilfe.',
      tapIntoExpertKnowledgeGeneric: 'Vereinbaren Sie unkompliziert einen Beratungstermin für einen Videoanruf. Nutzen Sie das Wissen unserer Expeditionsexperten, wenn Sie Fragen zu einem bestimmten Reiseziel haben.',
      launchChatBot: 'Chat beginnen',
      helpWithBooking: 'Mehr Informationen',
      helpWithBookingIntro: 'Manche Kartenanbieter erheben bei der Zahlungsverarbeitung von außerhalb der EU, UK und US ausgegebenen. Bitte bei ihrer bank nach Hurtigruten Expeditions übernimmt keine Verantwortung für diese Gebühren',
      bookingHelpTitle: 'Wir akzeptieren Visa, Mastercard, Maestro, UnionPay, JCB, und Sofort.',
      bookingHelpIntro: 'Kann ich eine Anzahlung leisten? Wir können eine mehr als 30 Tage vor Abreise vorgenommene Buchung mit einer nicht erstattbaren Zahlung von 20% 14 Tage lang reservieren.',
      noThanks: 'Nein danke',
      yesPlease: 'Ja bitte',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Anzahlung leisten | Liegt der Abreisetermin weniger als 30 Tage nach der Buchung, ist eine Zahlung der Gesamtsumme erforderlich. Der Restbetrag muss 30 Tage vor Abreise beglichen werden.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Mehr erfahren',
      askQuestions: 'Vereinbaren Sie unkompliziert einen Beratungstermin für einen Videoanruf. Nutzen Sie das Wissen unserer Expeditionsexperten, wenn Sie Fragen zu einem bestimmten Reiseziel haben.',
      talkToOurExperts: 'Dürfen wir Ihnen behilflich sein?',
      scheduleCall: 'Schedule call'
    }
  },
  'gsw-CH': {
    talkToUs: {
      howCouldWeBestSupport: 'Bitte wählen Sie aus, wie wir Sie auf Ihrer Expeditions-Seereise am besten unterstützen können',
      joinTheLiveChat: 'Nehmen Sie am Live-Chat teil',
      hiHowMayIHelpYou: 'Hallo! Wie kann ich Ihnen helfen?',
      justNow: 'Gleich jetzt',
      scheduleAVideoCall: 'Videoanruf mit einem Expeditionsexperten vereinbaren',
      tapIntoExpertKnowledge: ' Vereinbaren Sie unkompliziert einen Termin für eine Videoberatung. Nutzen Sie das Wissen unserer Expeditionsexperten für alle Fragen zu Ihrem Reiseziel.',
      talkWithAnExpert: 'Sprechen Sie mit einem Experten',
      call: 'Tel.',
      email: 'E-Mail-Adresse',
      subtitle: 'Haben Sie eine Frage oder eine Buchungsanfrage? Nehmen Sie noch heute Kontakt mit uns auf',
      reservationsHours: 'Reservierungszeiten',
      scheduleExpertCall: 'Dürfen wir Ihnen behilflich sein?',
      hideBanner: 'Banner ausblenden',
      chat: 'Chat',
      openChat: 'Öffnen Sie jetzt einen Live-Chat mit einem unserer Kundendienstmitarbeiter und erhalten Sie sofort Hilfe.',
      tapIntoExpertKnowledgeGeneric: 'Vereinbaren Sie unkompliziert einen Beratungstermin für einen Videoanruf. Nutzen Sie das Wissen unserer Expeditionsexperten, wenn Sie Fragen zu einem bestimmten Reiseziel haben.',
      launchChatBot: 'Chat beginnen',
      helpWithBooking: 'Mehr Informationen',
      helpWithBookingIntro: 'Manche Kartenanbieter erheben bei der Zahlungsverarbeitung von außerhalb der EU, UK und US ausgegebenen. Bitte bei ihrer bank nach Hurtigruten Expeditions übernimmt keine Verantwortung für diese Gebühren',
      bookingHelpTitle: 'Wir akzeptieren Visa, Mastercard, Maestro, UnionPay, JCB und Sofort. ',
      bookingHelpIntro: 'Kann ich eine Anzahlung leisten? Wir können eine mehr als 30 Tage vor Abreise vorgenommene Buchung mit einer nicht erstattbaren Zahlung von 20% 14 Tage lang reservieren.',
      noThanks: 'Nein danke',
      yesPlease: 'Ja bitte',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Anzahlung leisten | Liegt der Abreisetermin weniger als 30 Tage nach der Buchung, ist eine Zahlung der Gesamtsumme erforderlich. Der Restbetrag muss 30 Tage vor Abreise beglichen werden.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Mehr erfahren',
      askQuestions: 'Vereinbaren Sie unkompliziert einen Beratungstermin für einen Videoanruf. Nutzen Sie das Wissen unserer Expeditionsexperten, wenn Sie Fragen zu einem bestimmten Reiseziel haben.',
      talkToOurExperts: 'Dürfen wir Ihnen behilflich sein?',
      scheduleCall: 'Schedule call'
    }
  },
  'fr-FR': {
    talkToUs: {
      howCouldWeBestSupport: 'Veuillez sélectionner ce que nous pouvons faire pour soutenir au mieux votre exploration',
      joinTheLiveChat: 'Connectez-vous au chat en direct',
      hiHowMayIHelpYou: 'Bonjour ! En quoi puis-je vous aider ?',
      justNow: 'En ce moment',
      scheduleAVideoCall: 'Programmez un appel vidéo avec l’un de nos experts en expédition',
      tapIntoExpertKnowledge: 'Appuyez-vous sur les connaissances de nos experts en expédition pour toutes vos questions relatives à votre destination : {destination}',
      talkWithAnExpert: 'Discutez avec un expert',
      call: 'Par téléphone',
      email: 'Par e-mail',
      subtitle: 'Vous avez une question ou une demande concernant votre réservation ? Contactez-nous dès aujourd’hui',
      reservationsHours: 'Heures des réservations',
      scheduleExpertCall: 'Programmez un appel avec un expert',
      hideBanner: 'Cacher la bannière',
      chat: 'Chat',
      openChat: 'Ouvrez une session de discussion en direct avec l’un des conseillers de notre service client pour recevoir une assistance immédiate.',
      tapIntoExpertKnowledgeGeneric: 'Appuyez-vous sur les connaissances de nos experts en expédition pour toutes vos questions relatives à une destination particulière.',
      launchChatBot: 'Lancer le chat',
      helpWithBooking: 'Plus d\'informations',
      helpWithBookingIntro: 'Certains organismes peuvent appliquer des frais de transaction à l’étranger pour les cartes bancaires émises en dehors de EU, UK et USa. Veuillez vous renseigner auprès de votre banque. Hurtigruten n’est en aucun cas responsable de ces frais.',
      bookingHelpTitle: 'Nous acceptons les paiements par Visa, Mastercard, Maestro, American Express, Card Bancaire, UnionPay, JCB, et Sofort. ',
      bookingHelpIntro: 'Puis-je programmer le versement de l’acompte? En cas de réservation plus de 90 jours avant le départ, nous pouvons maintenir votre réservation pendant 7 jours sur versement d’un acompte non remboursable de 25%.',
      noThanks: 'Non merci',
      yesPlease: 'Oui',
      talkWithAnExpertDescription: 'Vous avez besoin d’aide pour comprendre ce qui est inclus dans votre expédition avec Hurtigruten ou pour obtenir des réponses à vos questions ? Contactez nos experts dès aujourd’hui.',
      helpWithBookingIntroSecond: 'L’acompte | Si vous réservez moins de 90 jours avant le départ, le paiement du solde sera exigé.Tout solde impayé devra être réglé 90 jours avant le départ.',
      helpWithBookingIntroThird: '',
      findOutMore: 'En savoir plus',
      askQuestions: 'Appuyez-vous sur les connaissances de nos experts en expédition pour toutes vos questions relatives à une destination particulière',
      talkToOurExperts: 'Programmez un appel vidéo avec l’un de nos experts',
      scheduleCall: 'Schedule call'
    }
  },
  'da-DK': {
    talkToUs: {
      howCouldWeBestSupport: 'Vælg, hvordan vi bedst kan hjælpe med dit eventyr',
      joinTheLiveChat: 'Deltag i livechat',
      hiHowMayIHelpYou: 'Hej! Hvad kan jeg hjælpe med?',
      justNow: 'Lige nu',
      scheduleAVideoCall: 'Book et videoopkald med en ekspeditionsekspert',
      tapIntoExpertKnowledge: 'Benyt dig af vores ekspeditionseksperters viden, hvis du har spørgsmål om {destination}',
      talkWithAnExpert: 'Tal med en ekspert',
      call: 'Ring',
      email: 'E-mail',
      subtitle: 'Har du et spørgsmål eller en bestillingsforespørgsel? Kontakt os i dag',
      reservationsHours: 'Telefontid for reservationer',
      scheduleExpertCall: 'Book et opkald med en ekspert',
      hideBanner: 'Skjul banner',
      chat: 'Chat',
      openChat: 'Åbn en livechat med en af vores kundeservicemedarbejdere nu, og få hjælp med det samme.',
      tapIntoExpertKnowledgeGeneric: 'Benyt dig af vores ekspeditionseksperters viden, hvis du har spørgsmål om en specifik destination.',
      launchChatBot: 'Start chat',
      helpWithBooking: 'Mere information',
      helpWithBookingIntro: 'Nogle kortudbydere opkræver et udenlandsk transaktionsgebyr for kort udstedt uden for EU, UK og USA. Kontakt din bank, hvis du vil vide mere. Hurtigruten er ikke ansvarlig for dette gebyr og modtager ikke nogen andel af eller fordel fra disse gebyrer.',
      bookingHelpTitle: 'Vi accepterer Visa, Mastercard, Maestro og Trustly. ',
      bookingHelpIntro: 'Kan jeg indbetale et depositum? Vi kan holde en reservation i 7 dage med en indledende ikke-refunderbar betaling på 25%, hvis du bestiller mere end 90 dage før afrejse. ',
      noThanks: 'Nej tak',
      yesPlease: 'Ja tak',
      talkWithAnExpertDescription: 'Har du spørgsmål til, hvad der er omfattet i din ekspedition med Hurtigruten, eller har du brug for vejledning? Kontakt vores eksperter i dag.',
      helpWithBookingIntroSecond: 'Depositum | Afrejser mindre end 90 dage fra bestillingstidspunktet kræver fuld betaling. Eventuelle udeståender skal være betalt 90 dage før afrejsen.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Find ud af mere',
      askQuestions: 'Benyt dig af vores ekspeditionseksperters viden, hvis du har spørgsmål om en specifik destination',
      talkToOurExperts: 'Book et videoopkald med en af vores eksperter',
      scheduleCall: 'Book et opkald'
    }
  },
  'sv-SE': {
    talkToUs: {
      howCouldWeBestSupport: 'Välj hur vi bäst kan hjälpa dig med dina äventyr',
      joinTheLiveChat: 'Anslut till livechatt',
      hiHowMayIHelpYou: 'Hej! Hur kan jag hjälpa dig?',
      justNow: 'Precis nu',
      scheduleAVideoCall: 'Boka ett videosamtal med en expeditionsexpert',
      tapIntoExpertKnowledge: 'Om du har frågor om {destination} kan du kontakta våra expeditionsexperter för att få svar.',
      talkWithAnExpert: 'Prata med en expert',
      call: 'Telefon',
      email: 'E-post',
      subtitle: 'Har du en fråga eller vill du göra en bokning? Kontakta oss i dag',
      reservationsHours: 'Öppettider för bokningar',
      scheduleExpertCall: 'Boka ett samtal med en expert',
      hideBanner: 'Dölj banner',
      chat: 'Chatt',
      openChat: 'Öppna en livechatt med en av våra kundtjänstrepresentanter nu för att få hjälp direkt.',
      tapIntoExpertKnowledgeGeneric: 'Om du har frågor om ett visst resmål kan du kontakta våra expeditionsexperter för att få svar.',
      launchChatBot: 'Starta chatt',
      helpWithBooking: 'Mer information',
      helpWithBookingIntro: 'Vissa kortleverantörer kan debitera transaktionsavgifter utomlands för kort som utfärdats utanför EU, UK och USA vid behandling av betalningen. Kontakta din bank för information om avgifter. Hurtigruten ansvarar inte för den här avgiften.',
      bookingHelpTitle: 'Vi accepterar Visa, Mastercard, Maestro, American Express, and Trustly.',
      bookingHelpIntro: 'Kan jag göra en deposition? Vi kan reservera din bokning i 7 dagar med en första betalning på 25% som inte är återbetalningsbar om bokningen gäller en resa där det är mer än 90 dagar till avgång.',
      noThanks: 'Nej tack',
      yesPlease: 'Ja tack',
      talkWithAnExpertDescription: 'Behöver du hjälp med att förstå vad som ingår i din expedition med Hurtigruten eller har du andra frågor? Kontakta en av våra experter i dag.',
      helpWithBookingIntroSecond: 'Deposition | Vid avgångar inom mindre än 90dagar krävs full betalning. Eventuella utestående belopp måste betalas 90 dagar före avgång.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Läs mer',
      askQuestions: 'Om du har frågor om ett visst resmål kan du kontakta våra expeditionsexperter för att få svar.',
      talkToOurExperts: 'Boka ett videosamtal med en av våra experter',
      scheduleCall: 'Boka samtal'
    }
  },
  'nb-NO': {
    talkToUs: {
      howCouldWeBestSupport: 'Velg hvordan vi kan hjelpe deg på best mulig måte',
      joinTheLiveChat: 'Start chatten',
      hiHowMayIHelpYou: 'Hei! Hva kan jeg hjelpe deg med?',
      justNow: 'Akkurat nå',
      scheduleAVideoCall: 'Avtal en videosamtale med en ekspedisjonsekspert',
      tapIntoExpertKnowledge: 'Har du spørsmål om {destination}? Benytt deg av de kunnskapsrike ekspedisjonsekspertene våre!',
      talkWithAnExpert: 'Snakk med en ekspert',
      call: 'Ring',
      email: 'E‑post',
      subtitle: 'Lurer du på noe om bestilling eller noe annet? Kontakt oss i dag',
      reservationsHours: 'Reservert tid',
      scheduleExpertCall: 'Avtal en telefonsamtale med en ekspert',
      hideBanner: 'Skjul banner',
      chat: 'Chat',
      openChat: 'Start en chat med en av kundeservicemedarbeiderne våre nå, så får du hjelp umiddelbart.',
      tapIntoExpertKnowledgeGeneric: 'Har du spørsmål om en spesiell destinasjon? Benytt deg av kunnskapen til ekspedisjonsekspertene våre!',
      launchChatBot: 'Start chat',
      helpWithBooking: 'Mer informasjon',
      helpWithBookingIntro: 'Noen kortutstedere kan belaste et gebyr for transaksjoner i utlandet for kort utstedt utenfor EU, UK og US når de behandler betalingen. Ta kontakt med banken hvis du har spørsmål om disse gebyrene. Hurtigruten er ikke ansvarlig for dette gebyret. ',
      bookingHelpTitle: 'Du kan bruke Visa, Mastercard, Maestro, American Express, UnionPay, JCB og Trustly.',
      bookingHelpIntro: 'Kan jeg betale et depositum? Vi kan holde av en bestilling i 7 dager mot en første, ikke-refunderbar 25% delbetaling, hvis du bestiller mer enn 90 dager før avreise. ',
      noThanks: 'Nei takk',
      yesPlease: 'Ja, takk',
      talkWithAnExpertDescription: 'Trenger du hjelp til å forstå hva som er inkludert i ekspedisjonen din med Hurtigruten, eller er det noe annet du lurer på? Kontakt ekspertene våre i dag.',
      helpWithBookingIntroSecond: 'Depositum | For avreiser mindre enn 90 dager fra bestillingstidspunktet må hele beløpet betales. Eventuelt utestående beløp må betales innen 90 dager før avreise.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Finn ut mer',
      askQuestions: 'Har du spørsmål om en spesiell destinasjon? Benytt deg av de kunnskapsrike ekspedisjonsekspertene våre',
      talkToOurExperts: 'Avtal en videosamtale med en av ekspertene våre',
      scheduleCall: 'Avtal en telefonsamtale'
    }
  },
  'en': {
    talkToUs: {
      howCouldWeBestSupport: 'Please select how we could best support your exploration',
      joinTheLiveChat: 'Join the live chat',
      hiHowMayIHelpYou: 'Hi there! How may I help you?',
      justNow: 'Just now',
      scheduleAVideoCall: 'Schedule a video call with an Expedition Expert',
      tapIntoExpertKnowledge: 'Tap into the knowledge of our Expedition Experts for any questions you have on {destination}',
      talkWithAnExpert: 'Talk with an expert',
      call: 'Call',
      email: 'Email',
      subtitle: 'Have a question or booking enquiry? Get in touch with us today',
      reservationsHours: 'Reservations hours',
      scheduleExpertCall: 'Schedule an expert call',
      hideBanner: 'Hide banner',
      chat: 'Chat',
      openChat: 'Open a live chat with one of our customer service agents now and get help immediately.',
      tapIntoExpertKnowledgeGeneric: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination.',
      launchChatBot: 'Launch chat',
      helpWithBooking: 'More Information',
      helpWithBookingIntro: 'Some card providers may charge a foreign transaction fee for cards issued outside the EU, UK, and US. Please check with your bank regarding these fees. Hurtigruten is not responsible for this fee and does not receive anything from this.',
      bookingHelpTitle: 'We accept Visa, Mastercard, Maestro, UnionPay, and JCB.',
      bookingHelpIntro: 'Can I set up a deposit? We can hold a booking for 7 days with an initial non-refundable 25% payment if booking more than 90 days before departure.',
      noThanks: 'No thanks',
      yesPlease: 'Yes please',
      talkWithAnExpertDescription: 'Need help understanding whats part of your expedition with Hurtigruten or have any questions that you need guidance with? Reach out to our experts today.',
      helpWithBookingIntroSecond: 'Deposit Information | Departures less than 90 days from booking, will require full payment. Any outstanding balance must be settled 90 days prior to departure.',
      helpWithBookingIntroThird: '',
      findOutMore: 'Find out more',
      askQuestions: 'Tap into the knowledge of our Expedition Experts for any questions you have on a specific destination',
      talkToOurExperts: 'Schedule a video call with one of our Experts',
      scheduleCall: 'Schedule call'
    }
  }
}

export type TTalkToUs = {
  talkToUs: {
    howCouldWeBestSupport: string;
    joinTheLiveChat: string;
    hiHowMayIHelpYou: string;
    justNow: string;
    scheduleAVideoCall: string;
    tapIntoExpertKnowledge: string;
    talkWithAnExpert: string;
    call: string;
    email: string;
    subtitle: string;
    reservationsHours: string;
    scheduleExpertCall: string;
    hideBanner: string;
    chat: string;
    openChat: string;
    tapIntoExpertKnowledgeGeneric: string;
    launchChatBot: string;
    helpWithBooking: string;
    helpWithBookingIntro: string;
    bookingHelpTitle: string;
    bookingHelpIntro: string;
    noThanks: string;
    yesPlease: string;
    talkWithAnExpertDescription: string;
    helpWithBookingIntroSecond: string;
    helpWithBookingIntroThird: string;
    findOutMore: string;
    askQuestions: string;
    talkToOurExperts: string;
    scheduleCall: string;
  };
}
