import { ReactElement, useContext, useRef, useEffect } from 'react';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import {
  Button,
  Icon,
  Layout,
} from '@hurtigruten/design-system-components';
import clsx from 'clsx';
import { OptimizelyContext } from '@optimizely/react-sdk';

import events from '@src/optimizely-events-cache.json';
import ContentfulImage from '@components/atoms/ContentfulImage';
import { LazyVideo } from '@atoms';
import {
  CruiseFilterData,
  PlannerDestination
} from '@src/types/expeditionPlanner';
import NewExpeditionPlanner from '@components/expeditionPlanner/NewExpeditionPlanner';
import { useMediaQuery } from '@hooks/useMediaQuery';
import {
  breakpoints,
  getDateDifferenceInDays,
  renderContentfulDocumentToReactComponents
} from '@src/utils';
import { useLocale, useTranslate } from '@hooks';
import { common } from '@microcopies';
import {SharedParagraph} from "@components/shared/paragraph";

type TLandingPageHero = {
  description: string | null;
  body: Document | null;
  offer?: null | {
    expiryDate: string;
  };
  image?: {
    url: string;
    alt: string;
  };
  title: string;
  subHeading: string;
  video?: {
    url: string;
    title: string;
  } | null;
  cta?: null | {
    slug: string;
    label: string;
  };
  onCtaClick?: (href: string) => void;
  plannerDestinations: PlannerDestination[];
  plannerCruises: CruiseFilterData[];
  trackPlannerEngagement: () => void;
};

const Logo = () => (
  <svg
    width="522"
    height="750"
    viewBox="0 0 522 750"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="absolute desktop:left-[-10%] h-[595px] w-[414px] laptop:w-auto laptop:h-[620px] desktop:h-[750px]"
  >
    <g className="mix-blend-overlay" opacity="0.3">
      <path
        d="M290.988 568.122C284.66 583.819 275.061 596.97 256.736 600.861V143.829H278.724L355.909 399.069L290.988 568.122ZM482.925 601.937L398.525 325.483L455.508 166.375C462.722 147.809 474.585 145.161 494.069 143.829V136.173H400.682V143.829C419.444 143.171 434.589 149.799 434.589 170.35C434.589 173.006 433.148 182.285 430.264 190.24L393.467 303.609H392.027L348.47 143.829H386.061V136.173H256.736V7.66791H296.441V0H151.367V7.66791H190.266V358.067H106.166V143.754H145.066V136.086H0V143.754H39.6962V602.236H0V609.904H145.066V602.236H106.166V370.008H190.266V742.332H151.367V750H296.441V742.332H256.736V609.596H365.597V601.937C344.679 601.271 310.466 592.652 310.466 570.778C310.466 567.461 311.182 564.147 313.347 554.202L360.958 422.933H362.399L415.784 601.937H376.121V609.596H521.39V601.937H482.925Z"
        fill="white"
        className="mix-blend-soft-light"
      />
    </g>
  </svg>
);

const LandingPageHero = ({
  body,
  offer,
  image,
  title,
  subHeading,
  video,
  cta,
  onCtaClick,
  plannerDestinations,
  plannerCruises,
  trackPlannerEngagement
}: TLandingPageHero) => {
  const { optimizely } = useContext(OptimizelyContext);
  const locale = useLocale();
  const isMobile = useMediaQuery(breakpoints.tablet, true);
  const translate = useTranslate(common, (x) => x.common);
  const plannerRef = useRef<HTMLDivElement>(null);

  const daysToExpiry = getDateDifferenceInDays(
    new Date(new Date().getTime()),
    new Date(offer?.expiryDate ?? ''),
    false
  );

  const onPlannerInteract = (event: MouseEvent) => {
    if (
      plannerRef.current &&
      plannerRef.current.contains(event.target as Node)
    ) {
      trackPlannerEngagement();
    }
  };
  useEffect(() => {
    document.addEventListener('click', onPlannerInteract, { once: true });

    return () => {
      document.removeEventListener('click', onPlannerInteract);
    };
  }, []);

  return (
    <div className="w-full tablet:mt-0 bg-off-black">
      <div className="relative flex w-full overflow-hidden h-[595px] laptop:h-[620px] desktop:h-[750px] imageContainer">
        {video && <LazyVideo videoUrl={video.url} title={video.title} />}
        {!video && (
          <ContentfulImage
            objectFit="cover"
            src={image?.url ?? ''}
            alt={image?.alt}
            layout="fill"
            sizes={'100vw'}
          />
        )}
        <div className="absolute z-30 flex justify-center w-full">
          <div className="grid w-full grid-cols-12 mt-10" ref={plannerRef}>
            <NewExpeditionPlanner
              destinations={plannerDestinations}
              cruises={plannerCruises}
            />
          </div>
        </div>

        <div className="absolute w-full">
          <Layout container>
            <div
              className={clsx('relative pointer-events-none', {
                'flex justify-center pl-12': isMobile
              })}
            >
              <Logo />
            </div>
          </Layout>
        </div>

        <div className="absolute pointer-events-none z-10 bg-opacity-30 w-full h-[595px] laptop:h-[620px] desktop:h-[750px] bg-black"></div>

        <div
          className={clsx(
            'absolute pointer-events-none z-20 flex items-center w-full mt-[110px] laptop:mt-[130px] h-[480px] laptop:h-[490px] desktop:h-[610px]',
            {
              'text-center': isMobile
            }
          )}
        >
          <Layout container grid="50_50" gap="none">
            <Layout.Column>
              <span></span>
            </Layout.Column>
            <Layout.Column>
              <div className="pointer-events-auto">
                {daysToExpiry > 0 && daysToExpiry < 30 && (
                  <div
                    className={clsx('flex mb-2', {
                      'items-center justify-center': isMobile
                    })}
                  >
                    <div className="bg-hx-red text-[0.625rem] uppercase px-2 py-1 flex items-center gap-2 rounded-2xl text-white font-medium">
                      <Icon graphic="clock" color="light" />
                      {translate((x) => x.nDaysLeft, {
                        n: daysToExpiry.toString()
                      })}
                    </div>
                  </div>
                )}
                {title && (
                  <h1 className="mb-4 text-white text-[2.25rem] laptop:text-[3rem] desktop:text-[3.875rem] laptop:leading-[3rem] desktop:leading-[4rem] table:text-auto drop-shadow-[0_0px_4px_rgba(64,64,64,1)]">
                    {title} {subHeading}
                  </h1>
                )}
                {body && (
                  <div>
                    {renderContentfulDocumentToReactComponents(body, locale, {
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <SharedParagraph
                            className="!text-[1.125rem] laptop:!text-[1.5rem] laptop:!leading-[2rem] mb-0 drop-shadow-[0_0px_2px_rgba(0,0,0,1)]"
                            color="light"
                          >
                            {children}
                          </SharedParagraph>
                        ),
                        [BLOCKS.UL_LIST]: (_, c) => {
                          const children = c as ReactElement<{
                            children: ReactElement[];
                          }>[];
                          return children ? (
                            <ul>
                              {children.map((child, idx) => (
                                <li
                                  key={`${idx}`}
                                  className="text-[1.25rem] laptop:text-[1.5rem] leading-[2rem] pb-2"
                                  style={{ display: 'flex' }}
                                >
                                  <Icon
                                    graphic="add-line"
                                    color="light"
                                    className="inline-block pt-0 mr-1 tablet:pt-1"
                                  />
                                  {child.props.children[0]}
                                </li>
                              ))}
                            </ul>
                          ) : null;
                        }
                      }
                    })}
                  </div>
                )}
                {cta && (
                  <div
                    className={clsx({
                      'flex justify-center': isMobile
                    })}
                  >
                    <Button
                      appearance="secondary"
                      fillMobileWidth={false}
                      size="regular"
                      className="mt-4"
                      text={cta?.label}
                      {...(cta?.slug &&
                        !cta.slug.startsWith('#') && { href: cta.slug })}
                      {...(cta?.slug &&
                        cta.slug.startsWith('#') && { icon: 'arrow-down' })}
                      onClick={() => {
                        optimizely?.track(
                          events.click_offer_button_homepagehero
                        );
                        onCtaClick?.(cta?.slug ?? '');
                      }}
                    />
                  </div>
                )}
              </div>
            </Layout.Column>
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default LandingPageHero;
