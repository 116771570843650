// TODO remove this and all references after new destination test end
export const isAntarctica = (path: string) => {
  if (
    path.includes('antarctica') ||
    path.includes('antarktis') ||
    path.includes('antarctique')
  ) {
    return true;
  }
  return false;
};

export const isGreenland = (path: string) => {
  if (
    path.includes('greenland') ||
    path.includes('groenland') ||
    path.includes('gronland')
  ) {
    return true;
  }
  return false;
};

export const isNWP = (path: string) => {
  if (path.includes('passage') || path.includes('nordvestpassasjen')) {
    return true;
  }
  return false;
};

export const isSvalbard = (path: string) => {
  if (path.includes('svalbard') || path.includes('spitzberg')) {
    return true;
  }
  return false;
};

export const isNorway = (path: string) => {
  if (
    path.includes('norway') ||
    path.includes('norge') ||
    path.includes('norvege') ||
    path.includes('norwegen')
  ) {
    return true;
  }
  return false;
};

export const isIceland = (path: string) => {
  if (path.includes('iceland') || path.includes('island')) {
    return true;
  }
  return false;
};

export const isEurope = (path: string) => {
  if (path.includes('europe') || path.includes('europa')) {
    return true;
  }
  return false;
};

export const isBritishIsles = (path: string) => {
  if (
    path.includes('british') ||
    path.includes('britiske') ||
    path.includes('britische') ||
    path.includes('brittiska') ||
    path.includes('britanniques')
  ) {
    return true;
  }
  return false;
};

export const isSouthAmerica = (path: string) => {
  if (
    path.includes('sudamerika') ||
    path.includes('sydamerika') ||
    path.includes('south-america') ||
    path.includes('amerique-du-sud') ||
    path.includes('sor-amerika')
  ) {
    return true;
  }
  return false;
};

export const isEnabledDestination = (path: string) => {
  if (
    path.includes('galapagos') ||
    path.includes('alaska') ||
    isAntarctica(path) ||
    isGreenland(path) ||
    isNWP(path) ||
    isSvalbard(path) ||
    isNorway(path) ||
    isIceland(path) ||
    isSouthAmerica(path) ||
    isEurope(path) ||
    isBritishIsles(path)
  ) {
    return true;
  }
  return false;
};
