import { useEffect } from 'react';

const initChatbot = () => {
  try {
    if (typeof window.embeddedservice_bootstrap !== 'undefined') {
      window.embeddedservice_bootstrap.settings.language = 'en_US';
      window.embeddedservice_bootstrap.settings.enableUserInputForConversationWithBot =
        false;
      window.embeddedservice_bootstrap.init(
        '00DUB000003rRV3',
        'Staging_TravelHX',
        'https://travelhx--hxafpilot.sandbox.my.site.com/ESWStagingTravelHX1734602554229',
        {
          scrt2URL: 'https://travelhx--hxafpilot.sandbox.my.salesforce-scrt.com'
        }
      );
    }
  } catch (error) {
    console.error('Chatbot initialization failed!', error);
  }
};
const chatbotDestinations: Record<string, string[]> = {
  antarctica: ['antarctica-cruises'],
  galapagos: ['galapagos-islands-cruises']
};
export const shouldShowChatForDestination = (slug: string): boolean =>
  Object.values(chatbotDestinations).some((slugs) => slugs.includes(slug));
export const useChatbotScript = (
  showChat: boolean,
  isChatEnabled?: boolean,
  locale?: TLocale
) => {
  useEffect(() => {
    if (!isChatEnabled || locale !== 'en-gb') {
      return () => {};
    }
    const scriptSrc =
      'https://travelhx--hxafpilot.sandbox.my.site.com/ESWStagingTravelHX1734602554229/assets/js/bootstrap.min.js';
    const scriptExists = document.querySelector(`script[src="${scriptSrc}"]`);
    const addScript = () => {
      if (!scriptExists) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = scriptSrc;
        script.onload = () => {
          initChatbot();
        };
        document.body.appendChild(script);
      }
    };
    const removeScript = () => {
      if (scriptExists) {
        document.body.removeChild(scriptExists);
      }
    };
    if (showChat) {
      addScript();
    } else {
      removeScript();
    }
    return () => {
      removeScript();
    };
  }, [showChat, locale]);
};
