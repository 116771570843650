/* eslint-disable max-len */
// Auto generated file. Do not edit.

export const metaDescription: Record<string, TMetaDescription> = {
  'en-AU': {
    metaDescription: {
      cruiseOverview: {
        title: 'Find Your Cruise',
        description: ' Search for cruises in Norway, Antarctica, Greenland, North America and more.'
      },
      destinationOverview: {
        title: 'Destinations',
        description: ' Go on an expedition cruise to some of the most remote and fascinating destinations in the world, from the Arctic to Antarctica.'
      },
      newsletter: {
        title: 'Subscribe to our newsletter',
        description: ' Sign up for the Hurtigruten Expedition newsletter to stay up to date on the latest offers, news and events.'
      },
      newsletterSignupConfirmation: {
        title: 'Thank you for signing up to our newsletter',
        description: ' Thanks for signing up, you will be hearing from us soon!'
      },
      offerOverview: {
        title: 'Special offers on Expedition Cruises',
        description: 'HX has been sailing polar waters since 1896! Now is your chance to explore one of our many destinations with a special offer.'
      },
      profile: {
        title: 'Profile',
        description: ' '
      },
      bookings: {
        title: 'Booking Details',
        description: ' '
      },
      search: {
        title: 'Search',
        description: ' '
      },
      shipOverview: {
        title: 'Our Ships',
        description: ' Since 1893, the Hurtigruten ships have been a hallmark of the Norwegian coast. Today, our ships sail to the Arctic and Antarctica, and many destinations in between. On each ship you will find modern facilities carefully blended with true character.'
      },
      storiesOverview: {
        title: 'Get inspired by our stories and start exploring',
        description: ' Learn more about the spectacular destinations and attractions you can experience on a cruise with Hurtigruten.'
      },
      termsOverview: {
        title: 'Terms and Conditions',
        description: ' These terms and conditions apply to your booking for a holiday and/or sea passage in Norway and on Expedition cruises. Please read them carefully, as you will be legally bound by them.'
      },
      activitiesOverview: {
        title: 'Enhance your cruise',
        description: ' '
      },
      homePage: {
        title: 'Expedition Cruises | Cruise Packages and Holidays ',
        description: 'Expand your horizons and be part of unique expedition cruises in the seas of the Caribbean, Central America, and South America.'
      },
      error: {
        title: 'Error',
        description: 'Oh no! We can\'t find that page right now. Make sure you typed in the page address correctly or go back to your previous page.'
      },
      makeAPayment: {
        title: 'Make a payment',
        description: 'Please select one option below to access your cruise booking'
      }
    }
  },
  'en-GB': {
    metaDescription: {
      cruiseOverview: {
        title: 'Find Your Cruise',
        description: ' Search for cruises in Norway, Antarctica, Greenland, North America and more.'
      },
      destinationOverview: {
        title: 'Destinations',
        description: ' Go on an expedition cruise to some of the most remote and fascinating destinations in the world, from the Arctic to Antarctica.'
      },
      newsletter: {
        title: 'Subscribe to our newsletter',
        description: ' Sign up for the Hurtigruten Expedition newsletter to stay up to date on the latest offers, news and events.'
      },
      newsletterSignupConfirmation: {
        title: 'Thank you for signing up to our newsletter',
        description: ' Thanks for signing up, you will be hearing from us soon!'
      },
      offerOverview: {
        title: 'Special offers on Expedition Cruises',
        description: 'HX has been sailing polar waters since 1896! Now is your chance to explore one of our many destinations with a special offer.'
      },
      profile: {
        title: 'Profile',
        description: ' '
      },
      bookings: {
        title: 'Booking Details',
        description: ' '
      },
      search: {
        title: 'Search',
        description: ' '
      },
      shipOverview: {
        title: 'Our Ships',
        description: ' Since 1893, the Hurtigruten ships have been a hallmark of the Norwegian coast. Today, our ships sail to the Arctic and Antarctica, and many destinations in between. On each ship you will find modern facilities carefully blended with true character.'
      },
      storiesOverview: {
        title: 'Get inspired by our stories and start exploring',
        description: ' Learn more about the spectacular destinations and attractions you can experience on a cruise with Hurtigruten.'
      },
      termsOverview: {
        title: 'Terms and Conditions',
        description: ' These terms and conditions apply to your booking for a holiday and/or sea passage in Norway and on Expedition cruises. Please read them carefully, as you will be legally bound by them.'
      },
      activitiesOverview: {
        title: 'Enhance your cruise',
        description: ' '
      },
      homePage: {
        title: 'Expedition Cruises | Cruise Packages and Holidays ',
        description: 'Expand your horizons and be part of unique expedition cruises in the seas of the Caribbean, Central America, and South America.'
      },
      error: {
        title: 'Error',
        description: 'Oh no! We can\'t find that page right now. Make sure you typed in the page address correctly or go back to your previous page.'
      },
      makeAPayment: {
        title: 'Make a payment',
        description: 'Please select one option below to access your cruise booking'
      }
    }
  },
  'en-US': {
    metaDescription: {
      cruiseOverview: {
        title: 'Find Your Cruise',
        description: ' Search for cruises in Norway, Antarctica, Greenland, North America and more.'
      },
      destinationOverview: {
        title: 'Destinations',
        description: ' Go on an expedition cruise to some of the most remote and fascinating destinations in the world, from the Arctic to Antarctica.'
      },
      newsletter: {
        title: 'Subscribe to our newsletter',
        description: ' Sign up for the Hurtigruten Expedition newsletter to stay up to date on the latest offers, news and events.'
      },
      newsletterSignupConfirmation: {
        title: 'Thank you for signing up to our newsletter',
        description: ' Thanks for signing up, you will be hearing from us soon!'
      },
      offerOverview: {
        title: 'Special offers on Expedition Cruises',
        description: 'HX has been sailing polar waters since 1896! Now is your chance to explore one of our many destinations with a special offer.'
      },
      profile: {
        title: 'Profile',
        description: ' '
      },
      bookings: {
        title: 'Booking Details',
        description: ' '
      },
      search: {
        title: 'Search',
        description: ' '
      },
      shipOverview: {
        title: 'Our Ships',
        description: ' Since 1893, the Hurtigruten ships have been a hallmark of the Norwegian coast. Today, our ships sail to the Arctic and Antarctica, and many destinations in between. On each ship you will find modern facilities carefully blended with true character.'
      },
      storiesOverview: {
        title: 'Get inspired by our stories and start exploring',
        description: ' Learn more about the spectacular destinations and attractions you can experience on a cruise with Hurtigruten.'
      },
      termsOverview: {
        title: 'Terms and Conditions',
        description: ' These terms and conditions apply to your booking for a holiday and/or sea passage in Norway and on Expedition cruises. Please read them carefully, as you will be legally bound by them.'
      },
      activitiesOverview: {
        title: 'Enhance your cruise',
        description: ' '
      },
      homePage: {
        title: 'Expedition Cruises | Cruise Packages and Vacations ',
        description: 'Expand your horizons and be part of unique expedition cruises in the seas of the Caribbean, Central America, and South America.'
      },
      error: {
        title: 'Error',
        description: 'Oh no! We can\'t find that page right now. Make sure you typed in the page address correctly or go back to your previous page.'
      },
      makeAPayment: {
        title: 'Make a payment',
        description: 'Please select one option below to access your cruise booking'
      }
    }
  },
  'de-DE': {
    metaDescription: {
      cruiseOverview: {
        title: 'Finden Sie Ihre Seereise',
        description: ' Suchen Sie nach Seereisen in Norwegen, der Antarktis, Grönland, Nordamerika und mehr.'
      },
      destinationOverview: {
        title: 'Reiseziele',
        description: ' Gehen Sie auf Expeditions-Seereise und entdecken Sie die entlegensten und faszinierendsten Reiseziele der Welt, von der Arktis bis zur Antarktis.'
      },
      newsletter: {
        title: 'Melden Sie sich für unseren Newsletter an',
        description: ' Melden Sie sich für den Hurtigruten Expeditions Newsletter an, um Informationen zu aktuellen Angeboten, Neuigkeiten und Veranstaltungen zu erhalten.'
      },
      newsletterSignupConfirmation: {
        title: 'Vielen Dank für Ihre Anmeldung zu unserem Newsletter',
        description: ' Vielen Dank für Ihre Anmeldung, Sie werden bald von uns hören!'
      },
      offerOverview: {
        title: 'Spezial-Angebote für Expeditions-Seereisen',
        description: 'HX ist seit 1896 in polaren Gewässern unterwegs! Jetzt haben Sie die Gelegenheit, eines unserer zahlreichen Reiseziele mit einem Spezial-Angebot zu erkunden.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Buchungsdetails',
        description: ' '
      },
      search: {
        title: 'Suchen',
        description: ' '
      },
      shipOverview: {
        title: 'Unsere Schiffe',
        description: ' Seit 1893 sind Hurtigruten-Schiffe ein Markenzeichen der norwegischen Küste. Heute fahren unsere Schiffe in die Arktis und Antarktis und zu vielen weiteren Zielen. Auf jedem Schiff finden Sie moderne Einrichtungen, kombiniert mit authentischem Charakter.'
      },
      storiesOverview: {
        title: 'Lassen Sie sich von unseren Geschichten inspirieren und gehen Sie auf Entdeckungsreise',
        description: ' Erfahren Sie mehr über die spektakulären Ziele und besonderen Erlebnisse, die Sie auf einer Seereise mit Hurtigruten entdecken können.'
      },
      termsOverview: {
        title: 'Angebotsbedingungen',
        description: ' Diese Allgemeinen Geschäftsbedingungen gelten für Ihre Buchung einer Reise und/oder einer Schiffspassage in Norwegen oder einer Expeditions-Seereise. Lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch, da Sie rechtlich an sie gebunden sind.'
      },
      activitiesOverview: {
        title: 'Optimieren Sie Ihre Seereise',
        description: ' '
      },
      homePage: {
        title: 'Startseite',
        description: ' Besuchen Sie die norwegischen Fjorde, sehen Sie die Nordlichter, reisen Sie in die Antarktis oder zu anderen einzigartigen Reisezielen. Unsere Seereisen führen Sie in einige der unglaublichsten und unberührtesten Regionen der Erde.'
      },
      error: {
        title: 'Fehler',
        description: 'Oh nein! Wir können diese Seite gerade nicht finden. Vergewissern Sie sich, dass Sie die Adresse der Seite richtig eingegeben haben, oder gehen Sie zurück zu Ihrer zuletzt angesehenen Seite.'
      },
      makeAPayment: {
        title: 'Eine Zahlung vornehmen',
        description: 'Bitte wählen Sie eine der folgenden Optionen, um auf die Buchung Ihrer Seereise zuzugreifen'
      }
    }
  },
  'gsw-CH': {
    metaDescription: {
      cruiseOverview: {
        title: 'Finden Sie Ihre Seereise',
        description: ' Suchen Sie nach Seereisen in Norwegen, der Antarktis, Grönland, Nordamerika und mehr.'
      },
      destinationOverview: {
        title: 'Reiseziele',
        description: ' Gehen Sie auf Expeditions-Seereise und entdecken Sie die entlegensten und faszinierendsten Reiseziele der Welt, von der Arktis bis zur Antarktis.'
      },
      newsletter: {
        title: 'Melden Sie sich für unseren Newsletter an',
        description: ' Melden Sie sich für den Hurtigruten Expeditions Newsletter an, um Informationen zu aktuellen Angeboten, Neuigkeiten und Veranstaltungen zu erhalten.'
      },
      newsletterSignupConfirmation: {
        title: 'Vielen Dank für Ihre Anmeldung zu unserem Newsletter',
        description: ' Vielen Dank für Ihre Anmeldung, Sie werden bald von uns hören!'
      },
      offerOverview: {
        title: 'Spezial-Angebote für Expeditions-Seereisen',
        description: 'HX ist seit 1896 in polaren Gewässern unterwegs! Jetzt haben Sie die Gelegenheit, eines unserer zahlreichen Reiseziele mit einem Spezial-Angebot zu erkunden.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Buchungsdetails',
        description: ' '
      },
      search: {
        title: 'Suchen',
        description: ' '
      },
      shipOverview: {
        title: 'Unsere Schiffe',
        description: ' Seit 1893 sind Hurtigruten-Schiffe ein Markenzeichen der norwegischen Küste. Heute fahren unsere Schiffe in die Arktis und Antarktis und zu vielen weiteren Zielen. Auf jedem Schiff finden Sie moderne Einrichtungen, kombiniert mit authentischem Charakter.'
      },
      storiesOverview: {
        title: 'Lassen Sie sich von unseren Geschichten inspirieren und gehen Sie auf Entdeckungsreise',
        description: ' Erfahren Sie mehr über die spektakulären Ziele und besonderen Erlebnisse, die Sie auf einer Seereise mit Hurtigruten entdecken können.'
      },
      termsOverview: {
        title: 'Angebotsbedingungen',
        description: ' Diese Allgemeinen Geschäftsbedingungen gelten für Ihre Buchung einer Reise und/oder einer Schiffspassage in Norwegen oder einer Expeditions-Seereise. Lesen Sie diese Allgemeinen Geschäftsbedingungen sorgfältig durch, da Sie rechtlich an sie gebunden sind.'
      },
      activitiesOverview: {
        title: 'Optimieren Sie Ihre Seereise',
        description: ' '
      },
      homePage: {
        title: 'Startseite',
        description: ' Besuchen Sie die norwegischen Fjorde, sehen Sie die Nordlichter, reisen Sie in die Antarktis oder zu anderen einzigartigen Reisezielen. Unsere Seereisen führen Sie in einige der unglaublichsten und unberührtesten Regionen der Erde.'
      },
      error: {
        title: 'Fehler',
        description: 'Oh nein! Wir können diese Seite gerade nicht finden. Vergewissern Sie sich, dass Sie die Adresse der Seite richtig eingegeben haben, oder gehen Sie zurück zu Ihrer zuletzt angesehenen Seite.'
      },
      makeAPayment: {
        title: 'Eine Zahlung vornehmen',
        description: 'Bitte wählen Sie eine der folgenden Optionen, um auf die Buchung Ihrer Seereise zuzugreifen'
      }
    }
  },
  'fr-FR': {
    metaDescription: {
      cruiseOverview: {
        title: 'Trouvez votre croisière',
        description: 'Recherchez des croisières en Norvège, en Antarctique, au Groenland, en Amérique du Nord et vers bien d’autres destinations.'
      },
      destinationOverview: {
        title: 'Destinations',
        description: 'Participez à une croisière d’expédition pour découvrir quelques-unes des destinations les plus isolées et les plus fascinantes du monde, de l’Arctique à l’Antarctique.'
      },
      newsletter: {
        title: 'Inscrivez-vous à notre newsletter',
        description: 'Inscrivez-vous à la newsletter Hurtigruten Expedition pour vous tenir informé(e) des offres, des actualités et des événements les plus récents.'
      },
      newsletterSignupConfirmation: {
        title: 'Merci de votre inscription à notre newsletter',
        description: 'Merci de votre inscription, vous aurez bientôt de nos nouvelles !'
      },
      offerOverview: {
        title: 'Offres spéciales sur nos croisières d’expédition',
        description: 'HX navigue dans les eaux polaires depuis 1896! C’est le moment ou jamais d’explorer l’une de nos nombreuses destinations grâce à nos offres spéciales.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Détails de la réservation',
        description: ' '
      },
      search: {
        title: 'Recherche',
        description: ' '
      },
      shipOverview: {
        title: 'Nos navires',
        description: 'Depuis 1893, les navires Hurtigruten sont un véritable emblème de la côte norvégienne et au-delà. Aujourd’hui, nos navires voguent vers l’Arctique et l’Antarctique, ainsi que vers de nombreuses autres destinations.'
      },
      storiesOverview: {
        title: 'Laissez-vous inspirer par nos articles et commencez votre exploration',
        description: 'Découvrez les destinations et attractions spectaculaires que vous pouvez explorer lors d’une croisière avec Hurtigruten.'
      },
      termsOverview: {
        title: 'Conditions générales',
        description: 'Les présentes conditions générales s’appliquent à votre réservation pour des vacances et/ou un passage en mer en Norvège, et sur les croisières d’expédition. Veuillez les lire attentivement, car vous serez légalement lié par elles.'
      },
      activitiesOverview: {
        title: 'Complétez votre croisière',
        description: ' '
      },
      homePage: {
        title: 'Accueil',
        description: 'Découvrez les fjords norvégiens, admirez les aurores boréales et foulez l’Antarctique ou d’autres destinations uniques. Nos croisières vous conduisent dans certaines des régions les plus incroyables et les plus préservées de la planète.'
      },
      error: {
        title: 'Erreur',
        description: 'Oh non ! Nous ne parvenons pas à trouver cette page pour le moment. Assurez-vous d’avoir correctement saisi l’adresse de la page ou retournez à la page précédente.'
      },
      makeAPayment: {
        title: 'Effectuer un paiement',
        description: 'Veuillez sélectionner une option ci-dessous pour accéder à la réservation de votre croisière'
      }
    }
  },
  'da-DK': {
    metaDescription: {
      cruiseOverview: {
        title: 'Find dit krydstogt',
        description: ' Søg efter krydstogter i Norge, Antarktis, Grønland, Nordamerika og mange andre steder.'
      },
      destinationOverview: {
        title: 'Destinationer',
        description: ' Tag på et ekspeditionskrydstogt til nogle af de mest fjerntliggende og fascinerende destinationer i verden fra Arktis til Antarktis.'
      },
      newsletter: {
        title: 'Tilmeld dig vores nyhedsbrev',
        description: ' Tilmeld dig vores nyhedsbrev for Hurtigruten Expeditions for at holde dig opdateret om de seneste tilbud, nyheder og arrangementer.'
      },
      newsletterSignupConfirmation: {
        title: 'Tak, fordi du har tilmeldt dig vores nyhedsbrev.',
        description: ' Tak for din tilmelding. Du hører snart fra os.'
      },
      offerOverview: {
        title: 'Tilbud på ekspeditionskrydstogter',
        description: 'Vi har sejlet i polarfarvandene siden 1896. Her er din chance for at opleve en af vores mange destinationer med et særtilbud.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Bestillingsoplysninger',
        description: ' '
      },
      search: {
        title: 'Søg',
        description: ' '
      },
      shipOverview: {
        title: 'Vores skibe',
        description: ' Hurtigrutens skibe har været et kendt syn langs den norske kyst siden 1893. I dag sejler vores skibe til Arktis og Antarktis og mange andre destinationer derimellem. På hvert skib finder du moderne faciliteter omhyggeligt blandet med ægte originalitet.'
      },
      storiesOverview: {
        title: 'Bliv inspireret af vores historier, og tag på eventyr',
        description: ' Få mere at vide om de spektakulære destinationer og attraktioner, du kan opleve på et krydstogt med Hurtigruten.'
      },
      termsOverview: {
        title: 'Vilkår og betingelser',
        description: ' Disse vilkår og betingelser gælder for din bestilling af en ferie og/eller sejlads i Norge og på ekspeditionskrydstogter. Læs dem grundigt, da du vil være juridisk bundet af dem.'
      },
      activitiesOverview: {
        title: 'Aktiviteter og udflugter',
        description: ' '
      },
      homePage: {
        title: 'Start',
        description: ' Oplev de norske fjorde, se nordlyset, eller sæt foden på Antarktis eller andre enestående destinationer. Vores krydstogter tager dig med til nogle af klodens mest imponerende og uberørte områder.'
      },
      error: {
        title: 'Fejl',
        description: 'Åh, nej! Vi kan ikke finde siden lige nu. Kontroller, at du har indtastet den rigtige webadresse, eller gå tilbage til den forrige side.'
      },
      makeAPayment: {
        title: 'Foretag en betaling',
        description: 'Vælg én mulighed herunder for at få adgang til din krydstogtbestilling'
      }
    }
  },
  'sv-SE': {
    metaDescription: {
      cruiseOverview: {
        title: 'Hitta kryssningen för dig',
        description: ' Sök efter kryssningar i Norge, Antarktis, Grönland, Nordamerika och andra platser.'
      },
      destinationOverview: {
        title: 'Destinationer',
        description: ' Res med en expeditionskryssning till några av de mest avlägsna och fascinerande platserna på vår jord, från Arktis till Antarktis.'
      },
      newsletter: {
        title: 'Prenumerera på vårt nyhetsbrev',
        description: ' Registrera dig för Hurtigruten Expeditions nyhetsbrev för att hålla dig à jour med våra senaste erbjudanden, nyheter och evenemang.'
      },
      newsletterSignupConfirmation: {
        title: 'Tack för att du registrerat dig för vårt nyhetsbrev',
        description: ' Tack för att du registrerat dig! Vi hör snart av oss.'
      },
      offerOverview: {
        title: 'Erbjudanden för expeditionskryssningar',
        description: 'HX har trafikerat ishaven sedan 1896. Nu har även du möjlighet att upptäcka ett av våra många resmål med ett specialerbjudande.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Bokningsuppgifter',
        description: ' '
      },
      search: {
        title: 'Sök',
        description: ' '
      },
      shipOverview: {
        title: 'Våra fartyg',
        description: 'Hurtigrutenfartygen har varit ett fast inslag längs den norska kusten sedan 1893. På alla fartyg i dagens flotta hittar du alla moderna faciliteter, pietetsfullt införlivade med fartygets karaktär.'
      },
      storiesOverview: {
        title: 'Hämta inspiration i våra berättelser och börja ditt eget äventyr',
        description: ' Ta reda på mer om de spektakulära destinationer och sevärdheter du kan uppleva på en kryssning med Hurtigruten.'
      },
      termsOverview: {
        title: 'Allmänna villkor',
        description: ' Dessa allmänna villkor gäller din bokning för en semester och/eller havspassage i Norge och på expeditionskryssningar. Vänligen läs igenom dem noggrant då de är juridiskt bindande.'
      },
      activitiesOverview: {
        title: 'Ge din kryssning det lilla extra',
        description: ' '
      },
      homePage: {
        title: 'Hem',
        description: ' Upplev de norska fjordarna, se norrskenet eller besök Antarktis eller andra unika resmål. Med våra kryssningar kan du besöka några av världens mest fantastiska och orörda platser.'
      },
      error: {
        title: 'Fel',
        description: 'Åh nej! Vi kan för tillfället inte hitta den sidan. Se till att webbadressen är korrekt eller gå tillbaka till föregående sida.'
      },
      makeAPayment: {
        title: 'Gör en betalning',
        description: 'Välj ett av alternativen nedan för att få åtkomst till din kryssningsbokning'
      }
    }
  },
  'nb-NO': {
    metaDescription: {
      cruiseOverview: {
        title: 'Finn drømmereisen din',
        description: ' Søk etter cruise i Norge, Antarktis, Grønland, Nord-Amerika og flere destinasjoner.'
      },
      destinationOverview: {
        title: 'Destinasjoner',
        description: ' Dra på et ekspedisjonscruise til noen av de mest fjerntliggende og fascinerende stedene i verden, fra Arktis til Antarktis.'
      },
      newsletter: {
        title: 'Abonner på nyhetsbrevet vårt',
        description: ' Meld deg på nyhetsbrevet til Hurtigruten Expeditions for å få med deg de nyeste tilbudene, nyhetene og begivenhetene.'
      },
      newsletterSignupConfirmation: {
        title: 'Takk for at du meldte deg på nyhetsbrevet vårt',
        description: ' Takk for at du meldte deg på! Du hører fra oss snart.'
      },
      offerOverview: {
        title: 'Tilbud på ekspedisjonscruise',
        description: 'Vi har seilt i polare farvann siden 1896. Nå kan det være din tur til å utforske en av de mange destinasjonene våre med et helt spesielt tilbud. * Merk at tilbudene ikke kan kombineres.'
      },
      profile: {
        title: 'Profil',
        description: ' '
      },
      bookings: {
        title: 'Bookingdetaljer',
        description: ' '
      },
      search: {
        title: 'Søk',
        description: ' '
      },
      shipOverview: {
        title: 'Skipene våre',
        description: 'Siden 1893 har Hurtigrutens skip vært et kjennetegn langs norskekysten. I dag seiler skipene våre til Arktis og Antarktis, og mange destinasjoner der imellom. På hvert skip finner du moderne fasiliteter forsiktig blandet med genuin egenart.'
      },
      storiesOverview: {
        title: 'La deg inspirere av historiene våre og start utforskingen',
        description: ' Les mer om de spektakulære destinasjonene og attraksjonene du kan oppleve under et cruise med Hurtigruten.'
      },
      termsOverview: {
        title: 'Vilkår og betingelser',
        description: ' Disse vilkårene og betingelsene gjelder for bestilling av en ferie og/eller sjøreise i Norge og på ekspedisjonscruise. Les dem nøye, da du vil være juridisk bundet av dem.'
      },
      activitiesOverview: {
        title: 'Ta reisen til nye høyder',
        description: ' '
      },
      homePage: {
        title: 'Hjem',
        description: ' Besøk de norske fjordene, se nordlyset eller gå i land i Antarktis og andre unike destinasjoner. Cruisene våre tar deg med til noen av de mest fantastiske og uberørte stedene i verden.'
      },
      error: {
        title: 'Feil',
        description: 'Oi sann! Vi finner ikke den siden akkurat nå. Dobbeltsjekk at du skrev riktig adresse, eller gå tilbake til forrige side.'
      },
      makeAPayment: {
        title: 'Betal',
        description: 'Velg et av alternativene nedenfor for å få tilgang til bookingen din'
      }
    }
  },
  'en': {
    metaDescription: {
      cruiseOverview: {
        title: 'Find Your Cruise',
        description: ' Search for cruises in Norway, Antarctica, Greenland, North America and more.'
      },
      destinationOverview: {
        title: 'Destinations',
        description: ' Go on an expedition cruise to some of the most remote and fascinating destinations in the world, from the Arctic to Antarctica.'
      },
      newsletter: {
        title: 'Subscribe to our newsletter',
        description: ' Sign up for the Hurtigruten Expedition newsletter to stay up to date on the latest offers, news and events.'
      },
      newsletterSignupConfirmation: {
        title: 'Thank you for signing up to our newsletter',
        description: ' Thanks for signing up, you will be hearing from us soon!'
      },
      offerOverview: {
        title: 'Special offers on Expedition Cruises',
        description: 'HX has been sailing polar waters since 1896! Now is your chance to explore one of our many destinations with a special offer.'
      },
      profile: {
        title: 'Profile',
        description: ' '
      },
      bookings: {
        title: 'Booking Details',
        description: ' '
      },
      search: {
        title: 'Search',
        description: ' '
      },
      shipOverview: {
        title: 'Our Ships',
        description: ' Since 1893, the Hurtigruten ships have been a hallmark of the Norwegian coast. Today, our ships sail to the Arctic and Antarctica, and many destinations in between. On each ship you will find modern facilities carefully blended with true character.'
      },
      storiesOverview: {
        title: 'Get inspired by our stories and start exploring',
        description: ' Learn more about the spectacular destinations and attractions you can experience on a cruise with Hurtigruten.'
      },
      termsOverview: {
        title: 'Terms and Conditions',
        description: ' These terms and conditions apply to your booking for a holiday and/or sea passage in Norway and on Expedition cruises. Please read them carefully, as you will be legally bound by them.'
      },
      activitiesOverview: {
        title: 'Enhance your cruise',
        description: ' '
      },
      homePage: {
        title: 'Expedition Cruises | Cruise Packages and Vacations ',
        description: 'Expand your horizons and be part of unique expedition cruises in the seas of the Caribbean, Central America, and South America.'
      },
      error: {
        title: 'Error',
        description: 'Oh no! We can\'t find that page right now. Make sure you typed in the page address correctly or go back to your previous page.'
      },
      makeAPayment: {
        title: 'Make a payment',
        description: 'Please select one option below to access your cruise booking'
      }
    }
  }
}

export type TMetaDescription = {
  metaDescription: {
    cruiseOverview: {
      title: string;
      description: string;
    };
    destinationOverview: {
      title: string;
      description: string;
    };
    newsletter: {
      title: string;
      description: string;
    };
    newsletterSignupConfirmation: {
      title: string;
      description: string;
    };
    offerOverview: {
      title: string;
      description: string;
    };
    profile: {
      title: string;
      description: string;
    };
    bookings: {
      title: string;
      description: string;
    };
    search: {
      title: string;
      description: string;
    };
    shipOverview: {
      title: string;
      description: string;
    };
    storiesOverview: {
      title: string;
      description: string;
    };
    termsOverview: {
      title: string;
      description: string;
    };
    activitiesOverview: {
      title: string;
      description: string;
    };
    homePage: {
      title: string;
      description: string;
    };
    error: {
      title: string;
      description: string;
    };
    makeAPayment: {
      title: string;
      description: string;
    };
  };
}
