/** @file Pure functions, private to the SharedParagraph component. */

import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Builds the `className` string for the SharedParagraph component.
 *
 * Combines base styles, conditional styles based on `props`, and any additional
 * class names passed as arguments using `clsx` and `tailwind-merge`.
 *
 * @param {Object} props - Configuration object for paragraph styles.
 * @param {'dark'|'light'} [props.color] - Sets the text color. Defaults to undefined.
 * @param {string} [props.className] - Additional class names to merge.
 * @param {boolean} [props.noMargin] - If true, removes the bottom margin (`!m-0`).
 * @param {'small'|'medium'|'large'} [props.textSize] - Specifies the text size.
 * @param {'uppercase'|'bold'} [props.textStyle] - Specifies the text style.
 * @returns {string} - The computed `className` string.
 */
export const buildParagraphClassName = (props: {
  color?: 'dark' | 'light';
  className?: string;
  noMargin?: boolean;
  textSize?: 'small' | 'medium' | 'large';
  textStyle?: 'uppercase' | 'bold';
}): string =>
  twMerge(
    'mb-6',
    clsx({
      'text-white': props.color === 'light',
      'text-black': props.color === 'dark',
      'text-sm': props.textSize === 'small',
      'text-base': props.textSize === 'medium',
      'text-lg': props.textSize === 'large',
      'uppercase font-medium text-2xs tracking-widest':
        props.textStyle === 'uppercase',
      'font-medium': props.textStyle === 'bold',
      '!m-0': props.noMargin
    }),
    props.className
  );
