import React, { FunctionComponent } from 'react';

import { ISharedParagraphProps } from '@components/shared/paragraph/SharedParagraph-types';
import { buildParagraphClassName } from '@components/shared/paragraph/SharedParagraph-utilities';

const SharedParagraph: FunctionComponent<ISharedParagraphProps> = ({
  children,
  className,
  color = 'dark',
  testId,
  noMargin = false,
  textSize = 'medium',
  textStyle
}) => (
  <p
    {...(testId ? { 'data-testid': testId } : {})}
    className={buildParagraphClassName({
      color,
      className,
      noMargin,
      textSize,
      textStyle
    })}
  >
    {children}
  </p>
);

export default SharedParagraph;
